import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Recipe } from './recipe.model';
import { Ingredient } from '../shared/ingredient.model';
import { ShoppingListService } from '../shopping-list/shopping-list.service';

@Injectable()
export class RecipeService {

    recipesChanged = new Subject<Recipe[]>();

    // private recipes: Recipe[] = [
    //     // tslint:disable-next-line:max-line-length
    //     new Recipe(
    //         'A test Recipe 1',
    //         'this is a test 1',
    //         'http://localhost/1.jpg',
    //         [
    //             new Ingredient('Banana', 1),
    //             new Ingredient('Apple', 5)
    //         ]),
    //     // tslint:disable-next-line:max-line-length
    //     new Recipe(
    //         'A test Recipe 2',
    //         'this is a test 2',
    //         'http://localhost/2.png',
    //         [
    //             new Ingredient('Raspberry', 3),
    //             new Ingredient('Blurberry', 7)
    //         ]),
    // ];

    private recipes: Recipe[] = [];

    constructor(private shoppingListService: ShoppingListService) { }

    setRecipes(recipes: Recipe[]) {
        this.recipes = recipes;
        this.recipesChanged.next(this.recipes.slice());
    }

    getRecipes() {
        return this.recipes.slice();
    }

    addIngredientsToShoppingList(ingredients: Ingredient[]) {
        this.shoppingListService.addIngredients(ingredients);
    }

    getRecipe(index: number) {
        return this.recipes[index];
        // return this.recipes.slice()[index];
    }

    addRecipe(recipe: Recipe) {
        this.recipes.push(recipe);
        this.recipesChanged.next(this.recipes.slice());
    }

    updateRecipe(index: number, recipe: Recipe) {
        this.recipes[index] = recipe;
        this.recipesChanged.next(this.recipes.slice());
    }

    deleteRecipe(index: number) {
        this.recipes.splice(index, 1);
        this.recipesChanged.next(this.recipes.slice());
    }
}

