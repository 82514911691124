import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { DataStorageService } from '../../shared/data-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isAuthenticated = false;

  private subsUser: Subscription;

  constructor(private dsService: DataStorageService, private authService: AuthService) { }

  ngOnInit() {
    this.subsUser = this.authService.user.subscribe(
      user => {
        this.isAuthenticated = !!user; // this.isAuthenticated = !user ? false : true;
        // console.log(!user);
        // console.log(!!user);
      }
    );
  }

  ngOnDestroy() {
    this.subsUser.unsubscribe();
  }

  onSave() {
    this.dsService.storeRecipes();
  }

  onFetch() {
    this.dsService.fetchRecipes().subscribe();
  }

  onLogout() {
    this.authService.logout();
  }


}
