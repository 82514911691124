import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { EnrollInfo } from './enroll-info.model';


@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {

  enrollForm: FormGroup;
  enrollInfo: EnrollInfo = null;

  constructor(private http: HttpClient) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {

    this.enrollInfo = new EnrollInfo('', '', '', '', '', '', new Date());

    this.enrollForm = new FormGroup({
      firstName: new FormControl(this.enrollInfo.firstName, Validators.required),
      lastName: new FormControl(this.enrollInfo.lastName, Validators.required),
      email: new FormControl(this.enrollInfo.email, Validators.required),
      phone: new FormControl(this.enrollInfo.phone, Validators.required),
      zipcode: new FormControl(this.enrollInfo.zipcode, Validators.required),
      state: new FormControl(this.enrollInfo.state, Validators.required)
    });
  }

  onEnroll() {
    this.enrollInfo = this.enrollForm.value as EnrollInfo;
    this.http.post('https://ohda-dcf4d.firebaseio.com/amarabacus/enrolls.json', this.enrollInfo).subscribe(
      response => {
        console.log(response);
        // console.log(this.enrollInfo);
        // this.enrollInfo = new EnrollInfo('', '', '', '', '', '', new Date());
        // console.log(this.enrollInfo);
        this.enrollForm.reset();
      });
  }

}
