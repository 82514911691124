// import { AuthService } from './auth/auth.service';
import { Component,
  // OnInit
} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
// export class AppComponent implements OnInit {

  images = [
    '../assets/img/girl.jpg',
  ];

  constructor(
    // private authService: AuthService
    ) { }

  // ngOnInit() {
  //   this.authService.autoLogin();
  // }

}
