import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ContactComponent } from './main/pages/contact/contact.component';
import { HomeComponent } from './main/pages/home/home.component';
import { FaqComponent } from './main/pages/faq/faq.component';
import { OurProgramComponent } from './main/pages/our-program/our-program.component';
import { AboutUsComponent } from './main/pages/about-us/about-us.component';
import { AbacusComponent } from './main/pages/abacus/abacus.component';
import { EnrollmentComponent } from './main/pages/enrollment/enrollment.component';

const appRoutes: Routes = [
    // { path: '', redirectTo: '/recipes', pathMatch: 'full' },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent},
    { path: 'abacus', component: AbacusComponent},
    { path: 'aboutus', component: AboutUsComponent},
    { path: 'program', component: OurProgramComponent},
    { path: 'faq', component: FaqComponent},
    { path: 'contact', component: ContactComponent },
    { path: 'enrollment', component: EnrollmentComponent },
    // {
    //     path: 'cp',
    //     loadChildren: () => import('./recipes/recipes.module').then(m => m.RecipesModule)
    // },
    // {
    //     path: 'recipes',
    //     loadChildren: () => import('./recipes/recipes.module').then(m => m.RecipesModule)
    // },
    // {
    //     path: 'shopping-list',
    //     loadChildren: () => import('./shopping-list/shopping-list.module').then(m => m.ShoppingListModule)
    // },
    {
        path: 'auth',
        loadChildren: () => import('./auth.module').then(m => m.AuthModule)
    },
    { path: '**', redirectTo: '/home' },

];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
