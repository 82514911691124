export class EnrollInfo {
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public phone: string,
        public zipcode: string,
        public state: string,
        public date: Date
        ) {
    }
}
