import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-program',
  templateUrl: './our-program.component.html',
  styleUrls: ['./our-program.component.css']
})
export class OurProgramComponent implements OnInit {

  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

}
