import { Directive, HostListener, HostBinding, ElementRef, Renderer } from '@angular/core';

@Directive({
    selector: '[appDropdown]'
})
export class DropdownDirective {
    @HostBinding('class.show') isOpen = false;

    // @HostListener('click') toggleOpen() {
    //     this.isOpen = !this.isOpen;
    //     const part = this.el.nativeElement.querySelector('.dropdown-menu');
    //     this.renderer.setElementClass(part, 'show', this.isOpen);
    // }

    @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
        this.isOpen = this.el.nativeElement.contains(event.target) ? !this.isOpen : false;
        const part = this.el.nativeElement.querySelector('.dropdown-menu');
        this.renderer.setElementClass(part, 'show', this.isOpen);
    }

    constructor(private el: ElementRef, private renderer: Renderer) {
    }


}
