import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { AbacusComponent } from './pages/abacus/abacus.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurProgramComponent } from './pages/our-program/our-program.component';
import { FaqComponent } from './pages/faq/faq.component';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { LocationsComponent } from './pages/locations/locations.component';

@NgModule({
    declarations: [
        ContactComponent,
        HomeComponent,
        AbacusComponent,
        AboutUsComponent,
        OurProgramComponent,
        FaqComponent,
        EnrollmentComponent,
        LocationsComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
    ]
})
export class MainSiteModule {

}
